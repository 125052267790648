import { render, staticRenderFns } from "./changeFullName.vue?vue&type=template&id=936ca9bc&scoped=true"
import script from "./changeFullName.vue?vue&type=script&lang=js"
export * from "./changeFullName.vue?vue&type=script&lang=js"
import style0 from "./changeFullName.vue?vue&type=style&index=0&id=936ca9bc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "936ca9bc",
  null
  
)

export default component.exports