<template>
  <div class="change-payment-password">
    <Header
      title="Change payment password"
    />
    <div class="content">
      <div class="section">
        <span>Original password</span>
      </div>
      <div class="inputBox">
        <input
          type="text"
          class="input"
          v-model="oldPwd"
          placeholder="Original password"
        />
      </div>
      <div class="section">
        <span>Password</span>
      </div>
      <div class="inputBox">
        <input
          type="password"
          class="input"
          v-model="newPwd"
          placeholder="Password"
        />
      </div>
      <div class="section">
        <span>Confirm Password</span>
      </div>
      <div class="inputBox">
        <input
          type="password"
          class="input"
          v-model="newPwd2"
          placeholder="Confirm Password"
        />
      </div>
      <div class="df_btn" @click="Submit()">Submit</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      oldPwd: "",
      newPwd: "",
      newPwd2: "",
      userInfo: {},
    };
  },
  methods: {
    async Submit() {
      if (!this.oldPwd || !this.newPwd || !this.newPwd2) {
        this.showInfo('Please fill in the complete information');
        return;
      }
      if (this.newPwd2 !== this.newPwd) {
        this.showInfo('The two passwords do not match');
        return;
      }

      this.getAjax(
        "api/zopuwmbfapsnb/bbywisnzjawm/changePayPwd",
        {
          oldPwd:this.oldPwd,
          newPwd:this.newPwd
        },
        "post"
      ).then((res) => {
        window.history.back();
      });
    },
  },
  mounted() {
    
  },
};
</script>

<style lang="less" scoped>
.change-payment-password {
  padding-top: 90px;
  .content {
    padding: 0 10px 10px;
    line-height: 1.6;
    .accountBox {
      width: 100%;
      height: 135px;
      background: url("@/assets/images/deposite_bg.png") no-repeat;
      background-size: cover;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      h3 {
        color: #fff;
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
      }
      .money {
        color: #fff;
        font-weight: bold;
        margin-top: 10px;
        span {
          font-size: 15px;
        }
        em {
          font-size: 22px;
          margin-left: 10px;
        }
      }
      p {
        font-size: 12px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
      }
    }
    .section {
      display: flex;
      align-items: center;
      margin-top: 15px;
      span {
        flex: 1;
        color: #25abc3;
        font-weight: bold;
      }
      .button {
        width: 100px;
        height: 20px;
        line-height: 18px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        border-radius: 3px;
        background: #1dc0b9;
        border: 1px solid #49dad4;
      }
    }
    .tips {
      font-size: 12px;
    }
    .inputBox {
      height: 46px;
      border-radius: 3px;
      padding: 0 10px;
      background: #fff;
      margin-top: 5px;
      border: 2px solid #c4e8ef;
      .input {
        height: 100%;
        width: 100%;
        color: #333;
        font-size: 14px;
        background: #fff;
        outline: none;
        border: 0;
      }
    }
  }
}
</style>
