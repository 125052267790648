<template>
	<div class="regeist">
		<div class="head">
			<h1>Register</h1>
			<div class="logo-container">
				<img src="@/assets/images/logo.png" alt="" class="logo" />
			</div>
		</div>
		<div class="content">
			<h2>Register</h2>
			<div class="tips">Please register to access more content</div>
			<div class="textbox">
				<span>Username</span>
				<div class="inputBox">
					<input type="text" class="inputtext" v-model="form.username" placeholder="phone/username" />
				</div>
			</div>
			<div class="textbox">
				<span>Password</span>
				<div class="inputBox">
					<input type="password" v-model="form.password" class="inputtext" placeholder="Type Password" />
				</div>
			</div>
			<div class="textbox">
				<span>Phone Number</span>
				<div class="inputBox">
					<input type="text" v-model="form.phone" class="inputtext" placeholder="Type Phone Number"
						@input="restrictToNumbersAndPlus" />
				</div>
			</div>
			<div class="textbox">
				<span>Invitation Code</span>
				<div class="inputBox">
					<input type="text" v-model="form.inviteCode" class="inputtext" placeholder="Type Invitation Code" />
				</div>
			</div>
			<div class="agreeBox">
				<div class="checkBox" @click="isChecked = !isChecked">
					<img src="@/assets/images/check.png" v-if="!isChecked" alt="" />
					<img src="@/assets/images/checked.png" v-else alt="" />
				</div>
				<span>Agree with</span>
				<a href="javascript:;" @click="goAgree()">《User Registration Agreement》</a>
			</div>
			<div class="df_btn" @click="regeist()">Register Now</div>
			<div class="regeist_btn" @click="login()">Login Now</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {},
		data() {
			return {
				isChecked: false,
				form: {
					inviteCode: "",
					password: "",
					phone: "",
					username: "",
				},
			};
		},
		methods: {
			login() {
				this.$router.push("/login");
			},
			restrictToNumbersAndPlus(event) {
				let inputValue = event.target.value;
				let numericValue = inputValue.replace(/[^0-9+]/g, "");
				this.form.phone = numericValue;
			},
			goAgree() {
				this.$router.push("/agreement");
			},
			regeist() {
				if (!this.isChecked) {
					this.showInfo('Read the 《User Registration Agreement》 first')
					return;
				}
				if (this.form.username.includes(' ')) {
					this.showInfo('Account cannot have spaces');
					return;
				}
				if (this.form.phone.includes(' ')) {
					this.showInfo('Phone number cannot have spaces');
					return;
				}
				if (this.form.inviteCode.includes(' ')) {
					this.showInfo('Invitation code cannot have spaces');
					return;
				}
				this.getAjax('api/zopuwmbfapsnb/bbywisnzjawm/register', {
					...this.form
				}, 'post').then(res => {
					this.showInfo(res.data, '', () => {
						this.$router.push("/login");
					})
				})
			},
		},
		async created() {},
	};
</script>
<style lang="less" socped>
	.regeist {
		.head {
			width: 100%;
			height: 40vh;
			background: url("@/assets/images/nav_bg.png") no-repeat;
			background-size: cover;

			.logo-container {
				width: 100%;
				max-width: 150px;
				/* 设置logo图的最大宽度 */
				margin: 50px auto;
			}

			@media (max-width: 375px) {
				.logo-container {
					margin: 30px auto;
					max-width: 100px;
					/* 在小屏幕上设置较小的宽度 */
				}
			}

			.logo {
				width: 100%;
				height: auto;
			}

			h1 {
				color: #fff;
				font-size: 18px;
				height: 36px;
				line-height: 34px;
				padding: 0 15px;
			}

			p {
				font-size: 12px;
				color: #97bec3;
				padding: 0 10px;
			}
		}

		.content {
			padding: 15px;

			h2 {
				font-size: 20px;
				color: #27a3c7;
				padding: 15px 0;
			}

			.tips {
				font-size: 14px;
				color: #666;
			}

			.agreeBox {
				display: flex;
				align-items: center;
				margin-top: 10px;
				color: #27a3c7;

				.checkBox {
					width: 24px;
					height: 24px;
					margin-right: 5px;

					img {
						width: 100%;
					}
				}

				a {
					color: #27a3c7;
					text-decoration: underline;
				}
			}

			.textbox {
				margin-top: 10px;
				border: 2px solid #c1e7ee;
				border-radius: 5px;
				display: flex;
				align-items: center;
				padding: 15px;

				span {
					margin-right: 15px;
					color: #27a3c7;
					font-size: 13px;
				}

				.inputBox {
					flex: 1;

					.inputtext {
						border: 0;
						outline: none;
						text-align: right;
						width: 100%;
					}
				}
			}

			.forget {
				font-size: 20px;
				margin-top: 30px;
				text-align: center;
				text-decoration: underline;
				font-weight: bold;
				color: #27a3c7;
			}

			.df_btn {
				margin: 15px auto;
			}

			.regeist_btn {
				width: 60%;
				height: 32px;
				line-height: 32px;
				text-align: center;
				background-image: linear-gradient(to right, #25abc4, #1acbb5);
				background-size: cover;
				color: #fff;
				margin: 0 auto;
				border-radius: 5px;
				font-size: 14px;
				opacity: 0.4;
			}
		}
	}
</style>