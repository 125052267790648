<template>
  <div class="deposit">
    <Header
      title="Deposit"
      rightNav="History"
      @handleClickRightNav="handleClickRightNav"
    />
    <div class="content">
      <div class="accountBox">
        <h3>Account Amount</h3>
        <div class="money">
          <span>USDT</span>
          <em>{{ userInfo.walletAmount }}</em>
        </div>
      </div>
      <div class="moneyBox">
        <div
          :class="['item', item.money === choiceMoney ? 'on' : '']"
          @click="clickItem(item)"
          v-for="item in moneyList"
          :key="item.money"
        >
          <h3>USDT</h3>
          <div class="value">{{ item.money }}</div>
        </div>
      </div>
      <div class="inputBox">
        <input
          type="number"
          class="input"
          v-model="choiceMoney"
          placeholder="Deposit Amount"
        />
      </div>
      <div class="df_btn" @click="depositSubmit()">Deposit Now</div>
    </div>
    <van-action-sheet
      v-model="showSheet"
      :actions="actions"
      cancel-text="Cancel"
      close-on-click-action
      @select="selectSheet"
      @cancel="showSheet = false"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      choiceMoney: "",
      userInfo: {},
      moneyList: [
        { money: "100.00" },
        { money: "500.00" },
        { money: "1000.00" },
      ],
      showSheet: false,
      actions: [{ name: "Ripemedia CS" }],
    };
  },
  methods: {
    handleClickRightNav() {
      this.$router.push('/rechargeHistory');
    },
    clickItem(item) {
      this.choiceMoney = item.money;
    },
    depositSubmit(){
      if(!this.choiceMoney){
        this.showInfo('Please input recharge amount!');
        return;
      }
      this.showSheet = true
    },
    selectSheet(opt) {
      window.open(opt.value,'_blank');
    },
  },
  async mounted() {
    //获取用户信息
    this.getAjax("api/zopuwmbfapsnb/bbywisnzjawm/getBalance", {}, "get").then((res) => {
      this.hideLoading();
      this.userInfo = res.data;
    });
    //获取客服列表
    const res2 = await this.getAjax('api/ahsuoapiemla/bbshwuzma/getCustomer',{},'get');
    this.actions = res2.data.filter(e=>!!e.name);
  },
};
</script>

<style lang="less" scoped>
.deposit {
  padding-top: 90px;
  .content {
    padding: 0 10px 10px;
    line-height: 1.6;
    .accountBox {
      width: 100%;
      height: 135px;
      background: url("@/assets/images/deposite_bg.png") no-repeat;
      background-size: cover;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      h3 {
        color: #fff;
        margin-top: 20px;
        font-size: 12px;
        font-weight: normal;
      }
      .money {
        color: #fff;
        font-weight: bold;
        margin-top: 20px;
        span {
          font-size: 15px;
        }
        em {
          font-size: 22px;
          margin-left: 10px;
        }
      }
    }
    .moneyBox {
      margin-top: 20px;
      display: flex;
      .item {
        flex: 1;
        flex-grow: 1;
        height: 74px;
        border: 1px solid #bde5ed;
        border-radius: 5px;
        padding: 10px 15px;
        box-sizing: border-box;
        color: #2fafc6;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
        h3 {
          font-weight: normal;
          font-size: 12px;
        }
        .value {
          padding-left: 15px;
          font-weight: bold;
          font-size: 16px;
        }
      }
      .on {
        background: #1dc0b9;
        border: 1px solid #1dc0b9;
        color: #fff;
      }
    }
    .inputBox {
      height: 46px;
      border-radius: 8px;
      padding: 0 10px;
      background: #d8f4f3;
      margin-top: 10px;
      .input {
        height: 100%;
        width: 100%;
        color: #039f98;
        font-size: 16px;
        background: #d8f4f3;
        outline: none;
        border: 0;
      }
    }
  }
}
</style>