<template>
  <div class="new-class-name">
    <Header
      title="Full Name"
    />
    <div class="content">
      <div class="title">
        <span>Full Name</span>
      </div>
      <div class="inputBox">
        <input
          type="text"
          class="input"
          v-model="fullName"
          placeholder="Full Name"
        />
      </div>
      <div class="df_btn" @click="Submit()">Submit</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      fullName: "",
    };
  },
  methods: {
    async Submit() {
      if (!this.fullName) {
        this.showInfo('Please fill in the complete information');
        return;
      }

      this.getAjax(
        "api/zopuwmbfapsnb/bbywisnzjawm/updateRealName",
        {
          phone:'',
          realName:this.fullName
        },
        "post"
      ).then((res) => {
        window.history.back();
      });
    },
  
  },
  mounted() {
    
  },
};
</script>

<style lang="less" scoped>
.new-class-name {
  padding-top: 90px;
  .content {
    padding: 0 10px 10px;
    line-height: 1.6;
    .accountBox {
      width: 100%;
      height: 135px;
      background: url("@/assets/images/deposite_bg.png") no-repeat;
      background-size: cover;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      h3 {
        color: #fff;
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
      }
      .money {
        color: #fff;
        font-weight: bold;
        margin-top: 10px;
        span {
          font-size: 15px;
        }
        em {
          font-size: 22px;
          margin-left: 10px;
        }
      }
      p {
        font-size: 12px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      margin-top: 15px;
      span {
        flex: 1;
        color: #25abc3;
        font-weight: bold;
      }
      .button {
        width: 100px;
        height: 20px;
        line-height: 18px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        border-radius: 3px;
        background: #1dc0b9;
        border: 1px solid #49dad4;
      }
    }
    .tips {
      font-size: 12px;
    }
    .inputBox {
      height: 46px;
      border-radius: 3px;
      padding: 0 10px;
      background: #fff;
      margin-top: 5px;
      border: 2px solid #c4e8ef;
      .input {
        height: 100%;
        width: 100%;
        color: #333;
        font-size: 14px;
        background: #fff;
        outline: none;
        border: 0;
      }
    }
  }
}
</style>
